import Layout, { Main } from "@seaweb/coral/components/Layout";
import AdminNavBar from "components/admin/navbar";
import AdminSideBar from "components/admin/sidebar";
import { User } from "model/user";
import { renderRoutes } from "react-router-config";
import { adminRoutes } from "routes";

export default function AdminPage({
  user,
}: {
  user: User
}): JSX.Element {
  return (
    <Layout>
      <AdminNavBar user={user} />
      <Layout>
        <AdminSideBar />
        <Main>{renderRoutes(adminRoutes)}</Main>
      </Layout>
    </Layout>
  );
}
