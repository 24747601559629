import { useState } from "react";
import IconButton from "@seaweb/coral/components/IconButton";
import DeleteIcon from '@seaweb/coral/icons/Delete';
import Tooltip, { Placements } from '@seaweb/coral/components/Tooltip';
import { ConfirmDialog, ConfirmStyle } from "components/dialog";
import { ToastPusher } from "components/global-toast";
import { Whitelist } from "model/whitelist";
import { WhitelistAPI } from "api";


export default function DeleteWhitelistButton({
  whitelist,
  successFn,
}: {
  whitelist: Whitelist;
  successFn: () => void;
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const deleteUser = async () => {
    try {
      WhitelistAPI.deleteWhitelist(whitelist);
      ToastPusher.success("Whitelist Deleted");
      successFn();
    } catch (e) {
      ToastPusher.failure(e);
    }
  }

  const onClick = () => {
    setIsOpen(true);
  }
  const onClose = () => {
    setIsOpen(false);
  }
  const onConfirm = () => {
    setIsOpen(false);
    deleteUser();
  }

  return (
    <div>
      <Tooltip title="Delete Whitelist" placement={Placements.Top}>
        <IconButton onClick={onClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        isOpen={isOpen}
        title="Delete Whitelist"
        message={(
          <div>
            Are you sure to delete <b>{whitelist.rule}</b>?
          </div>
        )}
        onClose={onClose}
        onConfirm={onConfirm}
        style={ConfirmStyle.Destructive}
        buttonText="Delete"
      />
    </div>
  );
}