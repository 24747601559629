import { StaffAPI } from "api";
import { Staff } from "model/staff";
import { User, UserRole } from "model/user"
import { useEffect, useState } from "react";
import Button, { ButtonVariants } from "@seaweb/coral/components/Button";
import { ColorTypes } from "@seaweb/coral/components/ThemeProvider";
import { useHistory } from "react-router";
import { AdminPathEnum } from "routes";
import StaffList from "./list";
import "./index.css"

export default function StaffPage({
  user,
}: {
  user: User;
}): JSX.Element {
  const history = useHistory();
  const [staffs, setStaffs] = useState<Staff[]>([]);

  useEffect(() => {
    (async () => {
      const staffs = await StaffAPI.getMyStaffs();
      console.log("staff: " + JSON.stringify(staffs));
      setStaffs(staffs);
    })();
  }, []);

  const onClickAdmin = () => {
    history.push(AdminPathEnum.Root);
  }

  const onClickHistory = (staff: Staff) => {
    history.push(`/history/${staff.staffID}`);
  }

  const renderAdminButton = (): JSX.Element => {
    if (user.role !== UserRole.Admin) {
      return <></>
    }
    return (
      <div>
        <Button className="admin-button"
          onClick={onClickAdmin}
          colorType={ColorTypes.Primary}
          variant={ButtonVariants.Outlined}>
          Admin Portal
        </Button>
      </div>
    );
  }

  return (
    <>
      {renderAdminButton()}
      <StaffList staffs={staffs} onClickHistory={onClickHistory} />
    </>
  );
}
