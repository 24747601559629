import { PusherPositions, usePusherContext } from '@seaweb/coral/components/Pusher';
import { useEffect } from 'react';
import Toast, { ToastTypes } from '@seaweb/coral/components/Toast';

let toastHandler: (type: ToastTypes, msg: string, duration?: number) => void

export class ToastPusher {
  static success(msg: string) {
    toastHandler(ToastTypes.Success, msg, 3000)
  }
  static failure(error: any) {
    toastHandler(ToastTypes.Error, error.message, 3000);
  }
}

export function GlobalToast(): JSX.Element {
  const { push } = usePusherContext();

  useEffect(() => {
    toastHandler = (type: ToastTypes, msg: string, duration?: number) => {
      console.log("duration: " + duration)
      push(<Toast type={type}>{msg}</Toast>, {
        position: PusherPositions.TopCenter,
        duration: duration,
      })
    }
  }, [push])

  return <></>
}