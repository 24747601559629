import { useState } from "react";
import IconButton from "@seaweb/coral/components/IconButton";
import DeleteIcon from '@seaweb/coral/icons/Delete';
import Tooltip, { Placements } from '@seaweb/coral/components/Tooltip';
import { UserAPI } from "api";
import { ConfirmDialog, ConfirmStyle } from "components/dialog";
import { User } from "model/user";
import { ToastPusher } from "components/global-toast";


export default function DeleteUserButton({
  user,
  successFn,
}: {
  user: User;
  successFn: () => void;
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const deleteUser = async () => {
    try {
      UserAPI.deleteUser(user);
      ToastPusher.success("User Deleted");
      successFn();
    } catch (e) {
      ToastPusher.failure(e);
    }
  }

  const onClick = () => {
    setIsOpen(true);
  }
  const onClose = () => {
    setIsOpen(false);
  }
  const onConfirm = () => {
    setIsOpen(false);
    deleteUser();
  }

  return (
    <div>
      <Tooltip title="Delete User" placement={Placements.Top}>
        <IconButton onClick={onClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        isOpen={isOpen}
        title="Delete User"
        message={(
          <div>
            Are you sure to delete <b>{user.email}</b>?
          </div>
        )}
        onClose={onClose}
        onConfirm={onConfirm}
        style={ConfirmStyle.Destructive}
        buttonText="Delete"
      />
    </div>
  );
}