
export enum UserRole {
  User = "user",
  Admin = "admin",
}

export const UserRoleList = [UserRole.User, UserRole.Admin]

export type User = {
  id: number;
  email: string;
  role: UserRole;
  created: number;
  updated: number;
}
