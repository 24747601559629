import { useState } from "react";
import IconButton from "@seaweb/coral/components/IconButton";
import EditIcon from '@seaweb/coral/icons/Edit';
import Tooltip, { Placements } from '@seaweb/coral/components/Tooltip';
import { UserAPI } from "api";
import UserDialog from "../user-dialog";
import { User } from "model/user";
import { ToastPusher } from "components/global-toast";


export default function EditUserButton({
  user,
  successFn,
}: {
  user: User;
  successFn: () => void;
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showDialog = () => setIsOpen(true)
  const closeDialog = () => setIsOpen(false)

  const editUser = async (user: User) => {
    try {
      setIsLoading(true);
      await UserAPI.patchUser(user);
      ToastPusher.success("User Updated");
      closeDialog();
      successFn();
    } catch (e) {
      ToastPusher.failure(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Tooltip title="Edit User" placement={Placements.Top}>
        <IconButton onClick={showDialog}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <UserDialog
        user={user}
        isOpen={isOpen}
        title="Edit User"
        buttonText="Update"
        disableButton={isLoading}
        onClose={closeDialog}
        onConfirm={(user) => {
          editUser(user);
        }}
      />
    </div>
  );
}