import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { HistorySection } from "model/history";
import { HistoryAPI } from "api";
import { ToastPusher } from "components/global-toast";
import HistoryList from "./list";

export default function HistoryPage(): JSX.Element {
  const { id: staffID } = useParams<{ id: string }>();
  const [sections, setSections] = useState<HistorySection[]>([]);

  useEffect(() => {
    (async () => {
      try {
        let sections = await HistoryAPI.getHistory(staffID);
        console.log("sections: " + JSON.stringify(sections));
        setSections(sections);
      } catch (e) {
        ToastPusher.failure(e);
      }
    })();
  }, [staffID]);

  return (
    <HistoryList sections={sections} />
  );
}
