import { useHistory, useParams } from "react-router";
import { AdminPathEnum } from "routes";
import { useEffect, useState } from "react";
import { UserAPI, FollowAPI } from "api";
import { User } from "model/user";
import { ToastPusher } from "components/global-toast";
import { Follow } from "model/follow";
import PageTitle from "components/admin/page-title";
import TableData, { Column } from "@seaweb/coral/components/TableData";
import InputGroup from "@seaweb/coral/components/InputGroup";
import AddFollowButton from "./add-follow-button";
import DeleteFollowButton from "./delete-follow-button";
import { formattedAdminTime } from '../utils';
import BackButton from "components/admin/back-button";

export default function ManageFollowsPage() {
  const history = useHistory();
  const { id: userid } = useParams<{ id: string }>();
  const [user, setUser] = useState<User | undefined>(undefined)
  const [follows, setFollows] = useState<Follow[]>([]);
  const [reloadCount, setReloadCount] = useState<number>(0);

  const reload = () => setReloadCount(reloadCount + 1)
  const onClickBack = () => history.push(AdminPathEnum.Root);

  useEffect(() => {
    (async () => {
      try {
        let user = await UserAPI.getUser(userid);
        setUser(user);
      } catch (e) {
        ToastPusher.failure(e);
      }
    })();
  }, [userid])

  useEffect(() => {
    (async () => {
      try {
        let follows = await FollowAPI.getFollows(userid);
        setFollows(follows);
      } catch (e) {
        ToastPusher.failure(e);
      }
    })();
  }, [reloadCount, userid])

  return (
    <div>
      <BackButton onClick={onClickBack} />
      <PageTitle title={"Manage Follows: " + (user ? user.email : userid)}
        rightElement={<AddFollowButton userID={parseInt(userid)} successFn={reload} />} />
      <TableData data={follows} rowKey={f => f.id}>
        <Column title="ID" width={30} render={f => f.id} />
        <Column title="SeaTalkID" width={60} render={f => f.staffID} />
        <Column title="Remarks" width={100} render={f => f.comment} />
        <Column title="Updated" width={100} render={f => formattedAdminTime(f.updated)} />
        <Column title="Actions" width={50} align="left" onClick={e => e.stopPropagation()}
          render={f => (
            <InputGroup gap={5}>
              <DeleteFollowButton follow={f} successFn={reload} />
            </InputGroup>
          )}
        />
      </TableData>
    </div>
  );
}