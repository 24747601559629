import { HistorySection } from "model/history";
import HistorySectionComponent from "../section";
import "./index.css"
import "common.css"

export default function HistoryList({
  sections,
}: {
  sections: HistorySection[];
}): JSX.Element {

  const renderSections = () => {
    return sections.map((section) => {
      return <HistorySectionComponent section={section} key={section.date} />
    })
  }

  return (
    <div className="history-list box vertical">
      {renderSections()}
    </div>
  );
}
