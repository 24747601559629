import { Sidebar as SideBarCoral } from "@seaweb/coral/components/Layout";
import SideMenu, { SideMenuItem } from "@seaweb/coral/components/SideMenu";
import UserIcon from '@seaweb/coral/icons/User';
import GlobeIcon from '@seaweb/coral/icons/Globe';
import { NavLink } from 'react-router-dom';
import { AdminPathEnum } from 'routes'

const SideMenuLink = (props: any) => (
  <SideMenuItem
    as={NavLink}
    activeClassName="coral-side-menu__item--selected"
    exact
    {...props}
  />
);

export default function AdminSideBar(): JSX.Element {
  return (
    <SideBarCoral>
      <SideMenu>
        <SideMenuLink to={AdminPathEnum.Root} isActive={(match: any, location: any) => {
          if (match) { return true; }
          return location.pathname.startsWith("/admin/users")
        }}>
          <UserIcon />
          User
        </SideMenuLink>
        <SideMenuLink to={AdminPathEnum.Whitelists}>
          <GlobeIcon />
          IP Whitelist
        </SideMenuLink>
      </SideMenu>
    </SideBarCoral >
  );
}