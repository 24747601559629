import { Navbar as CoralNavBar } from '@seaweb/coral/components/Layout';
import Menu, { MenuItem, Placements } from '@seaweb/coral/components/Menu';
import { AuthAPI } from 'api';
import { User } from 'model/user';
import { Link } from 'react-router-dom'
import { AdminPathEnum, PathEnum } from 'routes';
import './index.css'

export default function AdminNavBar({
  user,
}: {
  user: User;
}): JSX.Element {

  const logout = async () => {
    await AuthAPI.logout();
    window.location.pathname = PathEnum.Root;
  }

  return (
    <CoralNavBar className='navbar'>
      <Link className="title" to={AdminPathEnum.Root}>
        <div>BOC Admin Portal</div>
      </Link>
      <Menu
        button={<div className="user">{user ? user.email : ""}</div>}
        offset={[4, 3]}
        placement={Placements.BottomEnd}>
        <MenuItem className="menu-item" onClick={logout}>
          Logout
        </MenuItem>
      </Menu>
    </CoralNavBar>
  )
}