import { GlobalToast, ToastPusher } from 'components/global-toast';
import { User } from 'model/user';
import { useEffect, useState } from 'react';
import { UserAPI } from 'api';
import { Route, Switch } from 'react-router';
import { APIError, GlobalRequestErrorHandler } from 'api/request';
import LoginPage from 'pages/login';
import AdminPage from 'pages/admin';
import StaffPage from 'pages/staff';
import { PathEnum } from 'routes';
import HistoryPage from 'pages/history';
import LoadingPage from 'pages/loading';
import { render } from 'react-dom';

export default function App(): JSX.Element {
  const [didRequest, setDidRequest] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    (async () => {
      try {
        const user = await UserAPI.getMe();
        setUser(user);
      } catch (e: any) {
        if (e.status !== 401) {
          ToastPusher.failure(e);
        }
      } finally {
        setDidRequest(true);
      }
    })();
  }, []);

  useEffect(() => {
    if ((error instanceof APIError) && (error.status === 401)) {
      setUser(undefined);
    }
  }, [error]);

  const renderPage = (): JSX.Element => {
    if (user) {
      return (
        <Switch>
          <Route path={PathEnum.Admin}>
            <AdminPage user={user} />
          </Route>
          <Route path={PathEnum.History}>
            <HistoryPage />
          </Route>
          <Route path={PathEnum.Root}>
            <StaffPage user={user} />
          </Route>
        </Switch>
      );
    }
    else if (didRequest) {
      return (<LoginPage />);
    }
    else {
      return (<LoadingPage />);
    }
  }

  return (
    <div>
      <GlobalToast />
      <GlobalRequestErrorHandler onError={setError} />
      {renderPage()}
    </div>
  );
}
