import { useEffect, useState } from "react";
import Dialog, { DialogActions, DialogClose, DialogContent, DialogTitle } from "@seaweb/coral/components/Dialog";
import Button, { ButtonVariants } from "@seaweb/coral/components/Button";
import Field from "@seaweb/coral/components/Field";
import TextField from "@seaweb/coral/components/TextField";
import { ToastPusher } from "components/global-toast";
import { Follow } from "model/follow";
import { FollowAPI } from "api";
import "common.css"

export default function AddFollowButton({
  userID,
  successFn,
}: {
  userID: number;
  successFn: () => void;
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [staffID, setStaffID] = useState("");

  const showDialog = () => setIsOpen(true)
  const closeDialog = () => setIsOpen(false)

  useEffect(() => {
    setStaffID("")
  }, [isOpen])

  const addFollow = async () => {
    try {
      const follow: Follow = {
        id: 0,
        userID: userID,
        staffID: staffID,
        comment: "",
        created: 0, updated: 0
      }

      setIsLoading(true);
      await FollowAPI.postFollow(follow);
      ToastPusher.success("Follow Added")
      closeDialog();
      successFn();
    } catch (e) {
      ToastPusher.failure(e)
    } finally {
      setIsLoading(false);
    }
  }

  const onKeyDown = (event: any) => {
    if (event.key !== 'Enter') { return; }
    addFollow();
  }

  const shouldEnableButton = (): boolean => {
    return !isLoading && (staffID !== "")
  }

  return (
    <>
      <Button
        variant={ButtonVariants.Contained}
        onClick={showDialog}
      >Add Follow</Button >
      <Dialog isOpen={isOpen}>
        <DialogTitle className="font-large">
          Add Follow
          <DialogClose onClick={closeDialog} />
        </DialogTitle>
        <DialogContent>
          <Field label="SeaTalk ID" required>
            <TextField
              placeholder="1440411923" fullWidth value={staffID}
              onTextChange={setStaffID} onKeyDown={onKeyDown}
            />
          </Field>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} variant={ButtonVariants.Outlined}>Cancel</Button>
          <Button onClick={addFollow} disabled={!shouldEnableButton()}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
