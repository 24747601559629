import Button, { ButtonVariants } from "@seaweb/coral/components/Button";
import { ColorTypes } from "@seaweb/coral/components/ThemeProvider";
import ChevronLeft from "@seaweb/coral/icons/ChevronLeft";

export default function BackButton({
  onClick,
}: {
  onClick: () => void;
}): JSX.Element {

  return (
    <Button
      variant={ButtonVariants.Text}
      colorType={ColorTypes.Text}
      onClick={onClick}
      style={{ marginLeft: -10, padding: '0 12px 0 6px', minWidth: 0 }}
    >
      <ChevronLeft />
      <span>Back</span>
    </Button>
  );
}