import { ReactNode } from 'react';
import "./index.css";
import "common.css"

export default function PageTitle({
  title,
  rightElement,
}: {
  title: string;
  rightElement?: ReactNode;
}): JSX.Element {
  return (
    <div className="page-title-wrapper">
      <div className="page-title font-large">{title}</div>
      {rightElement}
    </div>
  )
}
