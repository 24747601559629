import { StaffAPI, UserAPI } from "api";
import BackButton from "components/admin/back-button";
import PageTitle from "components/admin/page-title";
import { ToastPusher } from "components/global-toast";
import { Staff } from "model/staff";
import { User } from "model/user";
import StaffList from "pages/staff/list";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { AdminPathEnum } from "routes";

export default function AdminStaffPage(): JSX.Element {
  const history = useHistory();
  const [staffs, setStaffs] = useState<Staff[]>([]);
  const [user, setUser] = useState<User | undefined>(undefined);
  const { id: userid } = useParams<{ id: string }>();

  useEffect(() => {
    (async () => {
      try {
        let staffs = await StaffAPI.getUserStaffs(userid);
        setStaffs(staffs);
      } catch (e) {
        ToastPusher.failure(e);
      }
    })();
  }, [userid]);

  useEffect(() => {
    (async () => {
      try {
        let user = await UserAPI.getUser(userid);
        setUser(user);
      } catch (e) {
        ToastPusher.failure(e);
      }
    })();
  }, [userid]);

  const onClickBack = () => {
    history.push(AdminPathEnum.Root);
  }

  const onClickHistory = (staff: Staff) => {
    const path = "/admin/users/" + userid + "/staffs/" + staff.staffID + "/history";
    history.push(path);
  }

  return (
    <>
      <BackButton onClick={onClickBack} />
      <PageTitle title={"User: " + (user ? user.email : "")} />
      <StaffList staffs={staffs} onClickHistory={onClickHistory} />
    </>
  );
}