import Button, { ButtonVariants } from "@seaweb/coral/components/Button";
import Dialog, { DialogActions, DialogClose, DialogContent, DialogTitle } from "@seaweb/coral/components/Dialog";
import { ColorTypes } from "@seaweb/coral/components/ThemeProvider";
import { Body } from '@seaweb/coral/components/Typography';
import "common.css"

export enum ConfirmStyle {
  Normal = 0,
  Destructive = 1,
}

export function ConfirmDialog({
  isOpen,
  title,
  message,
  onClose,
  onConfirm,
  buttonText,
  style,
}: {
  isOpen: boolean;
  title: string;
  message: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  buttonText?: string;
  style?: ConfirmStyle;
}): JSX.Element {

  const colorType = (): ColorTypes => {
    switch (style) {
      case ConfirmStyle.Destructive:
        return ColorTypes.Error
      default:
        return ColorTypes.Primary
    }
  }

  return (
    <Dialog isOpen={isOpen}>
      <DialogTitle className="font-large">
        {title}
        <DialogClose onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Body>{message}</Body>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant={ButtonVariants.Outlined}>Cancel</Button>
        <Button onClick={onConfirm} colorType={colorType()}>
          {buttonText ? buttonText : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}