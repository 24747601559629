import { useState } from "react";
import IconButton from "@seaweb/coral/components/IconButton";
import DeleteIcon from '@seaweb/coral/icons/Delete';
import Tooltip, { Placements } from '@seaweb/coral/components/Tooltip';
import { ConfirmDialog, ConfirmStyle } from "components/dialog";
import { ToastPusher } from "components/global-toast";
import { FollowAPI } from "api";
import { Follow } from "model/follow";


export default function DeleteFollowButton({
  follow,
  successFn,
}: {
  follow: Follow;
  successFn: () => void;
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const deleteUser = async () => {
    try {
      FollowAPI.deleteFollow(follow);
      ToastPusher.success("Follow Deleted");
      successFn();
    } catch (e) {
      ToastPusher.failure(e);
    }
  }

  const onClick = () => {
    setIsOpen(true);
  }
  const onClose = () => {
    setIsOpen(false);
  }
  const onConfirm = () => {
    setIsOpen(false);
    deleteUser();
  }

  return (
    <div>
      <Tooltip title="Delete Follow" placement={Placements.Top}>
        <IconButton onClick={onClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        isOpen={isOpen}
        title="Delete Follow"
        message={(
          <div>
            Are you sure to stop following <b>{follow.comment}</b>?
          </div>
        )}
        onClose={onClose}
        onConfirm={onConfirm}
        style={ConfirmStyle.Destructive}
        buttonText="Delete"
      />
    </div>
  );
}