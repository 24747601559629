import { useEffect, useState } from 'react';
import TableData, { Column } from '@seaweb/coral/components/TableData';
import UserCogIcon from '@seaweb/coral/icons/UserCog'
import EyeOpenIcon from '@seaweb/coral/icons/EyeOpen'
import InputGroup from '@seaweb/coral/components/InputGroup';
import IconButton from '@seaweb/coral/components/IconButton';
import Tooltip, { Placements } from '@seaweb/coral/components/Tooltip';
import { User } from 'model/user';
import { UserAPI } from 'api';
import PageTitle from 'components/admin/page-title';
import AddUserButton from './add-user-button';
import DeleteUserButton from './delete-user-button';
import { ToastPusher } from 'components/global-toast';
import EditUserButton from './edit-user-button';
import { useHistory } from 'react-router';
import { formattedAdminTime } from '../utils';

export default function ManageUsersPage(): JSX.Element {
  const history = useHistory();
  const [users, setUsers] = useState<User[]>([]);
  const [reloadCount, setReloadCount] = useState<number>(0);

  const reload = () => {
    setReloadCount(reloadCount + 1)
  }

  useEffect(() => {
    (async () => {
      try {
        const users = await UserAPI.getUsers();
        setUsers(users);
      } catch (e) {
        ToastPusher.failure(e);
      }
    })();
  }, [reloadCount]);

  const manageFollows = (user: User) => {
    history.push(`/admin/users/${user.id}/follows`);
  }

  const viewFollows = (user: User) => {
    history.push(`/admin/users/${user.id}/staffs`);
  }

  return (
    <div>
      <PageTitle title="Manage Users"
        rightElement={<AddUserButton successFn={reload} />} />
      <TableData data={users} rowKey={u => u.id}>
        <Column title="ID" width={30} render={u => u.id} />
        <Column title="Email" width={100} render={u => u.email} />
        <Column title="Role" width={50} render={u => u.role} />
        <Column title="Updated" width={100} render={u => formattedAdminTime(u.updated)} />
        <Column title="Actions" width={100} align="left" onClick={e => e.stopPropagation()}
          render={u => (
            <InputGroup gap={5}>
              <EditUserButton user={u} successFn={reload} />
              <Tooltip title="View Follows" placement={Placements.Top}>
                <IconButton onClick={() => viewFollows(u)}><EyeOpenIcon /></IconButton>
              </Tooltip>
              <Tooltip title="Manage Follows" placement={Placements.Top}>
                <IconButton onClick={() => manageFollows(u)}><UserCogIcon /></IconButton>
              </Tooltip>
              <DeleteUserButton user={u} successFn={reload} />
            </InputGroup>
          )}
        />
      </TableData>
    </div >
  )
}
