import { useEffect, useState } from 'react';
import TableData, { Column } from '@seaweb/coral/components/TableData';
import InputGroup from '@seaweb/coral/components/InputGroup';
import PageTitle from 'components/admin/page-title';
import { ToastPusher } from 'components/global-toast';
import { Whitelist } from 'model/whitelist';
import { WhitelistAPI } from 'api';
import AddWhitelistButton from './add-whitelist-button';
import DeleteWhitelistButton from './delete-whitelist-button';
import { formattedAdminTime } from '../utils';

export default function ManageWhitelistsPage(): JSX.Element {
  const [whitelists, setWhitelists] = useState<Whitelist[]>([]);
  const [reloadCount, setReloadCount] = useState<number>(0);

  const reload = () => {
    setReloadCount(reloadCount + 1)
  }

  useEffect(() => {
    (async () => {
      try {
        const whitelists = await WhitelistAPI.getWhitelists();
        setWhitelists(whitelists);
      } catch (e) {
        ToastPusher.failure(e);
      }
    })();
  }, [reloadCount]);

  return (
    <div>
      <PageTitle title="Manage Whitelist"
        rightElement={<AddWhitelistButton successFn={reload} />} />
      <TableData data={whitelists} rowKey={w => w.id}>
        <Column title="ID" width={30} render={w => w.id} />
        <Column title="Rule" width={90} render={w => w.rule} />
        <Column title="IP Range (Inclusive)" width={140}
          render={w => {
            if (w.ipStart === w.ipEnd) {
              return w.ipStart
            } else {
              return w.ipStart + " - " + w.ipEnd
            }
          }}
        />
        <Column title="Comment" width={100} render={w => w.comment} />
        <Column title="Updated" width={100} render={w => formattedAdminTime(w.updated)} />
        <Column title="Actions" width={50} align="left" onClick={e => e.stopPropagation()}
          render={w => (
            <InputGroup gap={5}>
              <DeleteWhitelistButton whitelist={w} successFn={reload} />
            </InputGroup>
          )}
        />
      </TableData>
    </div >
  )
}
