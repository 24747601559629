export enum Environment {
  Live = 'live',
  Staging = 'staging',
  Dev = 'dev',
}

export enum Host {
  Live = 'boc.seatalk.sea.com',
  Staging = 'boc.staging.alpha.insea.io',
  Dev = 'localhost:3000',
}

export enum ServerEndpoint {
  Live = 'https://boc.seatalk.sea.com',
  Staging = 'https://boc.staging.alpha.insea.io',
  Dev = 'http://localhost:8080',
}

function getEnvironment(): Environment {
  switch (window.location.host) {
    case Host.Live: return Environment.Live;
    case Host.Staging: return Environment.Staging;
    default: return Environment.Dev;
  }
}
export const env = getEnvironment();

function getEndpoint() {
  switch (env) {
    case Environment.Live: return ServerEndpoint.Live;
    case Environment.Staging: return ServerEndpoint.Staging;
    default: return ServerEndpoint.Dev;
  }
}
const endpoint = getEndpoint();
export default endpoint;

console.log(`[ENV] Env: ${env}, Endpoint: ${endpoint}`);