import { HistoryItem } from "model/history";
import { formattedHistoryTime } from "../utils";
import "./index.css"
import "common.css"

export default function HistoryItemComponent({
  item
}: {
  item: HistoryItem;
}): JSX.Element {

  let content: JSX.Element
  if (item.ip) {
    content = (<>
      <div className="text truncate">{item.country}</div>
      <div className="history-ip text expand">{"(" + item.ip + ")"}</div>
    </>);
  } else {
    content = (<div className="history-ip text expand">-</div>);
  }

  const created = "[" + formattedHistoryTime(item.created) + "]";
  const lastOnline = "Last Online: " + formattedHistoryTime(item.lastOnline);

  return (
    <div className="history-item box vertical">
      <div className="history-content box horizontal">
        {content}
        <div className="history-create text">{created}</div>
      </div>
      <div className="history-online text">{lastOnline}</div>
    </div >
  );
}