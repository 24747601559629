import { HistoryAPI } from "api";
import BackButton from "components/admin/back-button";
import PageTitle from "components/admin/page-title";
import { ToastPusher } from "components/global-toast";
import { HistorySection } from "model/history";
import HistoryList from "pages/history/list";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

export default function AdminHistoryPage(): JSX.Element {
  const history = useHistory();
  const params = useParams<{ user_id: string, staff_id: string }>();
  const [sections, setSections] = useState<HistorySection[]>([]);

  const onClickBack = () => {
    history.push("/admin/users/" + params.user_id + "/staffs");
  }

  useEffect(() => {
    (async () => {
      try {
        let sections = await HistoryAPI.getStaffHistory(params.staff_id);
        setSections(sections);
      } catch (e) {
        ToastPusher.failure(e);
      }
    })();
  }, [params]);

  return (
    <>
      <BackButton onClick={onClickBack} />
      <PageTitle title={"Staff: " + params.staff_id} />
      <HistoryList sections={sections} />
    </>
  );
}