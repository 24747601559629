import Box from '@seaweb/coral/components/Box';
import Button from '@seaweb/coral/components/Button';
import { AuthAPI } from 'api';

import "./index.css"
import "common.css"

export default function LoginPage() {

  const onClick = () => {
    AuthAPI.login();
  }

  return (
    <Box direction="column" alignItems="center">
      <h1 className='login-title'>BOC</h1>
      <Button className="login-button" onClick={onClick}>Google Login</Button>
    </Box>
  );
}