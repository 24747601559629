import { StaffDeviceInfo } from "model/staff";
import laptopLogo from "assets/images/laptop.png"
import phoneLogo from "assets/images/phone.png"
import "./index.css"
import "common.css"
import { formattedStaffTime } from "../utils";

export enum StaffDeviceType {
  Mobile, Web
}

export default function StaffDevice({
  type,
  info,
}: {
  type: StaffDeviceType;
  info: StaffDeviceInfo;
}): JSX.Element {

  if (!info.ip && !info.country) {
    console.log("nothing")
    console.log("info.ip: " + info.ip + ", info.country: " + info.country)
    return <></>
  }

  const imgSrc = (): string => {
    switch (type) {
      case StaffDeviceType.Mobile: return phoneLogo;
      case StaffDeviceType.Web: return laptopLogo;
    }
  }
  const lastOnline = (): JSX.Element => {
    if (!info.lastOnline) { return (<></>); }
    const lastOnline = "[" + formattedStaffTime(info.lastOnline) + "]";
    return (<div className="text">{lastOnline}</div>);
  }

  return (
    <div className="staff-device box horizontal">
      <img className="staff-device-icon" src={imgSrc()} />
      <div className="staff-device-info box vertical">
        <div className="staff-device-location box horizontal">
          <div className="text truncate">{info.country}</div>
          {lastOnline()}
        </div>
        <div className="text">{info.ip}</div>
      </div>
    </div>
  );
}