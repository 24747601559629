import AdminHistoryPage from 'pages/admin/history';
import ManageFollowsPage from 'pages/admin/manage-follows';
import ManageUsersPage from 'pages/admin/manage-users';
import ManageWhitelistsPage from 'pages/admin/manage-whitelists';
import AdminStaffPage from 'pages/admin/staff';

export enum PathEnum {
  Root = '/',
  Admin = '/admin',
  History = '/history/:id'
}

export enum AdminPathEnum {
  Root = '/admin',
  Whitelists = '/admin/whitelists',
  Follows = '/admin/users/:id/follows',
  Staffs = '/admin/users/:id/staffs',
  History = '/admin/users/:user_id/staffs/:staff_id/history'
}

export const adminRoutes = [
  {
    path: AdminPathEnum.Whitelists,
    component: ManageWhitelistsPage,
  },
  {
    path: AdminPathEnum.Follows,
    component: ManageFollowsPage,
  },
  {
    path: AdminPathEnum.History,
    component: AdminHistoryPage,
  },
  {
    path: AdminPathEnum.Staffs,
    component: AdminStaffPage,
  },
  {
    path: AdminPathEnum.Root,
    component: ManageUsersPage,
  },
];
