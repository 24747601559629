import { Staff } from "model/staff";
import StaffCard from "../card";
import "./index.css"
import "common.css"

export default function StaffList({
  staffs,
  onClickHistory,
}: {
  staffs: Staff[];
  onClickHistory: (staff: Staff) => void;
}): JSX.Element {

  const renderContent = (): JSX.Element[] => {
    return staffs.map((staff): JSX.Element => {
      return <StaffCard
        staff={staff}
        key={staff.userID}
        onClickHistory={() => { onClickHistory(staff) }} />
    })
  }

  return (
    <div className="staff-list box vertical">
      {renderContent()}
    </div>
  );
}