import { useEffect, useState } from "react";
import Button, { ButtonVariants } from "@seaweb/coral/components/Button";
import Dialog, { DialogActions, DialogClose, DialogContent, DialogTitle } from '@seaweb/coral/components/Dialog';
import TextField from '@seaweb/coral/components/TextField';
import Select, { SelectButton, SelectList, SelectOption } from '@seaweb/coral/components/Select';
import Field from '@seaweb/coral/components/Field';
import { User, UserRole, UserRoleList } from "model/user";
import isEmailValidFn from "utils/email";
import "common.css"

export default function UserDialog({
  user,
  isOpen,
  title,
  buttonText,
  disableButton,
  onClose,
  onConfirm,
}: {
  user?: User,
  isOpen: boolean,
  title: string;
  disableButton?: boolean;
  buttonText: string;
  onClose: () => void;
  onConfirm: (user: User) => void;
}): JSX.Element {

  const [email, setEmail] = useState("");
  const [role, setRole] = useState(UserRole.User);
  const [isEmailValid, setIsEmailValid] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setEmail(user ? user.email : "")
      setRole(user ? user.role : UserRole.User)
      setIsEmailValid(true);
    } else {
      setEmail("")
      setRole(UserRole.User)
      setIsEmailValid(true);
    }
  }, [isOpen, user])

  const onEmailChange = (text: string) => {
    setEmail(text);

    let isValid = isEmailValidFn(text);
    setIsEmailValid(isValid);
  }

  const onKeyDown = (event: any) => {
    if (event.key !== 'Enter') { return; }
    confirm();
  }

  const onClick = () => {
    confirm();
  }

  const confirm = () => {
    onConfirm({
      id: user ? user.id : 0,
      email: email,
      role: role,
      created: 0,
      updated: 0
    });
  }

  const shouldEnableButton = (): boolean => {
    let disable = disableButton ? disableButton : false
    return (!disable && email !== "" && isEmailValid);
  }

  const roleOptions = UserRoleList.map((role) => {
    return <SelectOption key={role} value={role}>{role}</SelectOption>;
  });

  return (
    <Dialog isOpen={isOpen}>
      <DialogTitle className="font-large">
        {title}
        <DialogClose onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Field label="Role" required>
          <Select value={role} onChange={(v) => setRole(v as UserRole)}>
            <SelectButton />
            <SelectList>{roleOptions}</SelectList>
          </Select>
        </Field>
        <Field
          label="Email" required invalid={!isEmailValid}
          message={isEmailValid ? "" : "Please enter a valid email"}
        >
          <TextField
            placeholder="example@sea.com" fullWidth value={email}
            onTextChange={onEmailChange} autoFocus={false}
            onKeyDown={onKeyDown}
          />
        </Field>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant={ButtonVariants.Outlined}>Cancel</Button>
        <Button onClick={onClick} disabled={!shouldEnableButton()}>{buttonText}</Button>
      </DialogActions>
    </Dialog>
  );
}