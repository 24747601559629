import { useState } from "react";
import Button, { ButtonVariants } from "@seaweb/coral/components/Button";
import { WhitelistAPI } from "api";
import { Whitelist } from "model/whitelist";
import WhitelistDialog from "../whitelist-dialog";
import { ToastPusher } from "components/global-toast";
import "common.css"

export default function AddWhitelistButton({
  successFn,
}: {
  successFn: () => void;
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showDialog = () => setIsOpen(true)
  const closeDialog = () => setIsOpen(false)

  const addWhitelist = async (whitelist: Whitelist) => {
    try {
      setIsLoading(true);
      await WhitelistAPI.postWhitelist(whitelist);
      ToastPusher.success("Whitelist Added")
      closeDialog();
      successFn();
    } catch (e) {
      ToastPusher.failure(e)
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Button
        variant={ButtonVariants.Contained}
        onClick={showDialog}
      >Add Whitelist</Button >
      <WhitelistDialog
        isOpen={isOpen}
        title="Add Whitelist"
        buttonText="Add"
        disableButton={isLoading}
        onClose={closeDialog}
        onConfirm={(whitelist) => {
          addWhitelist(whitelist);
        }}
      />
    </>
  );
}
