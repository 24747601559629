
export function formattedAdminTime(ts: number): string {
  const date = new Date(ts * 1000);

  const year = date.toLocaleDateString("en-us", { year: "numeric" });
  const day = date.toLocaleDateString("en-us", { day: "numeric" });
  const month = date.toLocaleDateString("en-us", { month: "2-digit" });
  const time = date.toLocaleTimeString("en-us", {
    hour: "2-digit", minute: "2-digit",
    second: "2-digit", hour12: false,
  });

  return year + "-" + month + "-" + day + " " + time;
}