import { useEffect, useState } from "react";
import Button, { ButtonVariants } from "@seaweb/coral/components/Button";
import Dialog, { DialogActions, DialogClose, DialogContent, DialogTitle } from '@seaweb/coral/components/Dialog';
import TextField from '@seaweb/coral/components/TextField';
import Field from '@seaweb/coral/components/Field';
import { Whitelist } from "model/whitelist";
import "common.css"
import { isIPCIDRValid } from "utils/ip";

export default function WhitelistDialog({
  isOpen,
  title,
  buttonText,
  disableButton,
  onClose,
  onConfirm,
}: {
  isOpen: boolean,
  title: string;
  disableButton?: boolean;
  buttonText: string;
  onClose: () => void;
  onConfirm: (whitelist: Whitelist) => void;
}): JSX.Element {

  const [rule, setRule] = useState("");
  const [comment, setComment] = useState("");
  const [isRuleValid, setIsRuleValid] = useState(true);

  useEffect(() => {
    setRule("")
    setComment("")
    setIsRuleValid(true);
  }, [isOpen])

  const onRuleChange = (text: string) => {
    setRule(text);

    let isValid = isIPCIDRValid(text)
    setIsRuleValid(isValid);
  }

  const onClick = () => {
    confirm();
  }

  const confirm = () => {
    onConfirm({
      id: 0,
      rule: rule,
      comment: comment,
      ipStart: "", ipEnd: "",
      ipStartInt: 0, ipEndInt: 0,
      created: 0, updated: 0
    });
  }

  const shouldEnableButton = (): boolean => {
    let disable = disableButton ? disableButton : false
    return (!disable && rule !== "" && isRuleValid);
  }

  return (
    <Dialog isOpen={isOpen}>
      <DialogTitle className="font-large">
        {title}
        <DialogClose onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Field
          label="Rule" required invalid={!isRuleValid}
          message={isRuleValid ? "" : "Please enter a valid IP or CIDR"}
        >
          <TextField
            placeholder="xxx.xxx.xxx.xxx/xx" fullWidth
            value={rule} onTextChange={onRuleChange}
          />
        </Field>
        <Field label="Comment">
          <TextField fullWidth value={comment} onTextChange={setComment} />
        </Field>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant={ButtonVariants.Outlined}>Cancel</Button>
        <Button onClick={onClick} disabled={!shouldEnableButton()}>{buttonText}</Button>
      </DialogActions>
    </Dialog>
  );
}