import { Staff } from "model/staff";
import StaffDevice, { StaffDeviceType } from "../device";
import HistoryLogo from "assets/images/history.png"
import "./index.css"
import "common.css"

export default function StaffCard({
  staff,
  onClickHistory,
}: {
  staff: Staff;
  onClickHistory: () => void;
}): JSX.Element {

  let name = staff.info.name;
  if (!name) {
    name = "ID: " + staff.staffID;
  }

  return (
    <div className="staff-card box horizontal">
      <div className="staff-card-info box vertical">
        <div className="text truncate">{name}</div>
        <StaffDevice
          type={StaffDeviceType.Mobile}
          info={staff.mobileDevice} />
        <StaffDevice
          type={StaffDeviceType.Web}
          info={staff.webDevice} />
      </div>
      <img className="staff-card-history-icon" src={HistoryLogo} onClick={onClickHistory} />
    </div>
  );
}
