import sendRequest from "./request"
import { UserResponse, UsersResponse, FollowsResponse, WhitelistResponse, StaffsResponse, HistoryResponse } from "./response";
import { User } from "model/user";
import { Follow } from "model/follow";
import { Whitelist } from "model/whitelist";
import { Staff } from "model/staff";
import { HistorySection } from "model/history";
import endpoint from "./env"

export class AuthAPI {

  public static login() {
    const redirect = encodeURIComponent(window.location.href);
    window.location.href = `${endpoint}/api/login?redirect_url=${redirect}`
  }

  public static async logout(): Promise<void> {
    await sendRequest("POST", "/api/logout", null);
  }
}

export class StaffAPI {

  public static async getMyStaffs(): Promise<Staff[]> {
    const res = await sendRequest<StaffsResponse>("GET", "/api/me/staffs", null)
    return res.staffs
  }

  public static async getUserStaffs(userID: number | string): Promise<Staff[]> {
    let url = "/api/admin/users/" + userID + "/staffs";
    const res = await sendRequest<StaffsResponse>("GET", url, null)
    return res.staffs
  }
}

export class HistoryAPI {

  public static async getHistory(staffID: string): Promise<HistorySection[]> {
    const res = await sendRequest<HistoryResponse>("GET", "/api/history/" + staffID, null)
    return res.sections
  }

  public static async getStaffHistory(staffID: string): Promise<HistorySection[]> {
    const url = "/api/admin/staffs/" + staffID + "/history";
    const res = await sendRequest<HistoryResponse>("GET", url, null);
    return res.sections
  }
}

export class UserAPI {

  public static async getMe(): Promise<User> {
    const res = await sendRequest<UserResponse>("GET", "/api/me", null);
    return res.user;
  }

  public static async getUsers(): Promise<User[]> {
    const res = await sendRequest<UsersResponse>("GET", "/api/admin/users", null);
    return res.users;
  }

  public static async getUser(id: number | string): Promise<User> {
    const res = await sendRequest<UserResponse>("GET", "/api/admin/users/" + id, null);
    return res.user;
  }

  public static async postUser(user: User): Promise<void> {
    await sendRequest("POST", "/api/admin/users", user);
  }

  public static async deleteUser(user: User): Promise<void> {
    await sendRequest("DELETE", "/api/admin/users/" + user.id, null);
  }

  public static async patchUser(user: User): Promise<void> {
    await sendRequest("PATCH", "/api/admin/users/" + user.id, user);
  }
}

export class FollowAPI {

  public static async getFollows(userID: string | number): Promise<Follow[]> {
    const path = "/api/admin/users/" + userID + "/follows"
    const res = await sendRequest<FollowsResponse>("GET", path, null);
    return res.follows;
  }

  public static async postFollow(follow: Follow): Promise<void> {
    await sendRequest("POST", "/api/admin/follows", follow);
  }

  public static async deleteFollow(follow: Follow): Promise<void> {
    await sendRequest("DELETE", "/api/admin/follows/" + follow.id, null);
  }
}

export class WhitelistAPI {

  public static async getWhitelists(): Promise<Whitelist[]> {
    const res = await sendRequest<WhitelistResponse>("GET", "/api/admin/whitelists", null);
    return res.whitelists
  }

  public static async postWhitelist(whitelist: Whitelist): Promise<void> {
    await sendRequest("POST", "/api/admin/whitelists", whitelist);
  }

  public static async deleteWhitelist(whitelist: Whitelist): Promise<void> {
    await sendRequest("DELETE", "/api/admin/whitelists/" + whitelist.id, null)
  }
}