import { HistorySection } from "model/history";
import HistoryItemComponent from "../item";
import "./index.css"
import "common.css"

export default function HistorySectionComponent({
  section
}: {
  section: HistorySection
}): JSX.Element {

  const renderItems = () => {
    return section.items.map((item): JSX.Element => {
      return <HistoryItemComponent item={item} key={item.id} />
    });
  }

  return (
    <div>
      <div className="history-section text">{section.date}</div>
      {renderItems()}
    </div>
  );
}
