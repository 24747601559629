import { useEffect } from 'react';
import endpoint from './env';
import { Response } from './response'

let requestErrorHandler: (e: any) => void

export class APIError extends Error {
  status: number;

  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

export default async function sendRequest<T>(method: string, path: string, body: any): Promise<T> {
  try {

    const url = endpoint + path;
    console.log("sendRequest " + method + ": " + url);

    let bodyJSON = null;
    if (body) {
      bodyJSON = JSON.stringify(body)
    }

    const response = await fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: bodyJSON,
      credentials: 'include',
    });

    console.log("response [" + response.status + "] " + url);

    if (response.status !== 200) {
      let msg = response.status.toString() + " " + response.statusText;
      try {
        const body = await response.json() as Response;
        if (body && body.msg !== "") {
          msg += ": " + body.msg;
        }
      } catch { }
      throw new APIError(response.status, msg);
    }
    return await response.json() as T;
  }
  catch (e) {
    if (requestErrorHandler) {
      requestErrorHandler(e)
    }
    throw e
  }
}

export function GlobalRequestErrorHandler({
  onError
}: {
  onError: (e: any) => void
}): JSX.Element {

  useEffect(() => {
    console.log("onError")
    requestErrorHandler = (e: any) => {
      onError(e);
    };
  }, [onError])

  return <></>
}
